const TIME_QUERIES = ['last_3', 'last_5', 'last_10', 'current_season'];
const DEFAULT_TIME_QUERY = 'last_3';
const CLUB_FRIENDLY_ID = '5f34ef232b928b3223dd632d';
class GameStatsQuerySelector {
  private selectedSeason;
  private timeQueries;
  private timeQuery;
  private gameSelections;
  private selectedLeagueId;
  private selectedLeague;

  private games;
  private leagues;

  private onChange;
  private filteredGames;
  private initialValue: any = {};

  private onlyPlayedGamesEnabled;
  private onlyPlayedGames;

  constructor(
    private SeasonService,
    private $scope,
    private $mdMenu,
  ) {
    this.timeQueries = TIME_QUERIES;
    this.gameSelections = {};
    this.selectedSeason = this.SeasonService.getSeason();
    this.timeQueries = TIME_QUERIES;
    this.timeQuery = this.timeQuery || DEFAULT_TIME_QUERY;
    this.selectedLeagueId = 'all';
    this.filteredGames = [];
    this.onlyPlayedGames = false;
  }

  $onInit() {
    this.$scope.$on('$mdMenuClose', this.onMenuClose);
    this.onChange({
      $season: this.selectedSeason,
      $timeQuery: this.timeQuery || DEFAULT_TIME_QUERY,
    });
  }

  $onChanges(changes) {
    if (changes.timeQuery) {
      this.timeQuery = changes.timeQuery.currentValue || DEFAULT_TIME_QUERY;
    }
    if (changes.games) {
      this.filterGame();
    }
  }

  isFriendlyGame(game) {
    return game.gameType === 'friendly' || game.league._id === '5f34ef232b928b3223dd632d';
  }

  onMenuClose = () => {
    this.timeQuery = this.initialValue.timeQuery;
    this.gameSelections = this.initialValue.gameSelections;
    this.selectedLeagueId = this.initialValue.selectedLeagueId;
    this.selectedLeague = this.initialValue.selectedLeague;
    this.onlyPlayedGames = this.initialValue.onlyPlayedGames;
    this.filterGame();
  };

  showMenu($mdMenu) {
    this.initialValue = {
      timeQuery: this.timeQuery,
      gameSelections: {...this.gameSelections},
      selectedLeagueId: this.selectedLeagueId,
      selectedLeague: this.selectedLeague,
      onlyPlayedGames: this.onlyPlayedGames,
    };
    $mdMenu.open();
  }

  filterGame() {
    if (!this.games) {
      this.filteredGames = [];
      return;
    }
    this.filteredGames = this.games.filter((g) => {
      if (this.selectedLeagueId === 'all') {
        return true;
      }
      return this.selectedLeagueId === CLUB_FRIENDLY_ID
        ? g.league._id === CLUB_FRIENDLY_ID || g.gameType === 'friendly'
        : g.league._id === this.selectedLeagueId && g.gameType !== 'friendly';
    });
  }

  selectTimeQuery(timeQuery) {
    this.gameSelections = {};
    this.timeQuery = timeQuery;
  }

  onLeagueSelectionChange() {
    this.filterGame();
    this.selectedLeague = this.leagues.find((l) => l._id === this.selectedLeagueId);
    this.gameSelections = {};
    if (!this.timeQuery) {
      this.timeQuery = DEFAULT_TIME_QUERY;
    }
  }

  toggleGameSelection(game) {
    this.gameSelections[game.gameId] = !this.gameSelections[game.gameId];
    this.timeQuery = this.totalSelectedGames > 0 ? null : DEFAULT_TIME_QUERY;
  }

  apply() {
    this.onChange({
      $league: this.selectedLeagueId,
      $timeQuery: this.timeQuery,
      $games: this.games.filter((g) => !!this.gameSelections[g.gameId]),
      $onlyPlayedGames: this.onlyPlayedGames,
    });
    this.$mdMenu.hide();
  }

  get totalSelectedGames() {
    return Object.keys(this.gameSelections).filter((key) => !!this.gameSelections[key]).length;
  }
}

angular.module('app.general').component('gamestatsQuerySelector', {
  templateUrl: 'general/components/gamestats-query-selector.html',
  controller: GameStatsQuerySelector as any,
  bindings: {
    games: '<',
    leagues: '<',
    isDisabled: '<',
    timeQuery: '<',
    onlyPlayedGames: '<',
    onlyPlayedGamesEnabled: '<',
    onChange: '&',
  },
});
